@import "base";

body.stm_layout_esperanza {
    .btn {
        padding: 16px 30px;
        font-weight: bold;
        border-radius: 4px;
        border-width: 1px;
        letter-spacing: 2px;
        text-transform: uppercase;
        &_outline.btn_third {
            &:hover {
                background-color: transparent !important;
            }
        }
    }
    .vc_btn3 {
        font-weight: bold;
    }
}